<template>
  <CHeader with-subheader>
    <div class="d-inline-flex w-100 mr-3">
      <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('sidebar.toggle_mobile')"
      />
      <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('sidebar.toggle_desktop')"
      />
      <CHeaderBrand class="mx-auto d-md-down-none d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="scroll-x scroll-tiny">
        <slot name="body">
          <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/" exact
              ><TMessage content="Home"
            /></CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/sale/dashboard" exact
              ><TMessage content="Sale"
            /></CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/purchase/dashboard" exact
              ><TMessage content="Purchase"
            /></CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/accounting/dashboard" exact
              ><TMessage content="Accounting"
            /></CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/warehouse/dashboard" exact
              ><TMessage content="Warehouse"
            /></CHeaderNavLink>
          </CHeaderNavItem>
        </slot>
      </CHeaderNav>
      <CHeaderNav class="ml-auto">
        <!-- <CHeaderNavItem class="px-3 c-d-legacy-none">
          <button
            @click="() => $store.commit('sidebar.toggle', 'sidebar_dark_mode')"
            class="c-header-nav-btn"
          >
            <CIcon
              v-if="$store.state.sidebar.sidebar_dark_mode"
              name="cil-sun"
            />
            <CIcon v-else name="cil-moon" />
          </button>
        </CHeaderNavItem> -->
        <slot name="header-nav-right"> </slot>
        <TheHeaderDropdownLang class="px-1" size="xl" />
        <TheHeaderDropdownNotif class="px-1" />
        <!-- <TheHeaderDropdownTasks class="px-1" />
        <TheHeaderDropdownMssgs class="px-1" /> -->
        <TheHeaderDropdownAccnt :root_path="root_path" class="px-1" />
      </CHeaderNav>
    </div>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
      <CHeaderNav
        class="ml-auto"
        v-show="sidebar_aside_exist"
        style="min-height: auto"
      >
        <CHeaderNavItem>
          <button
            class="c-header-nav-btn"
            @click="$store.commit('sidebar.toggle', 'sidebar_aside_show')"
          >
            <CIcon size="lg" name="cil-applications-settings" class="mr-2" />
          </button>
        </CHeaderNavItem>
      </CHeaderNav>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownLang from "./TheHeaderDropdownLang";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownLang,
    TheHeaderDropdownNotif,
  },
  props: {
    root_path: {
      type: String,
      default: "customer",
    },
  },
  computed: {
    sidebar_aside_exist() {
      return this.$store.state.sidebar.sidebar_aside_exist;
    },
  },
};
</script>
