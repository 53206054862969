<template>
  <CDropdown
    placement="bottom-end"
    :caret="false"
    in-nav
    class="c-header-nav-item mx-2"
    add-menu-classes="py-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-bell" />
        <CBadge shape="pill" color="danger">{{ countUnRead }}</CBadge>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="d-flex bg-light" style="min-width: 250px">
      <div class="d-flex">
        <TMessage content="Notification" bold />
        <TSpinner :loading="loading" class="ml-1" />
      </div>
      <CLink :title="$t('Refresh')" @click="fetchNoti" class="ml-auto">
        <CIcon name="cil-reload" width="16" />
      </CLink>
    </CDropdownHeader>
    <div class="scroll-y" style="max-height: 450px">
      <CDropdownItem
        v-for="noti in notifs"
        :key="noti.id"
        class="d-block"
        @click="toNoti(noti)"
      >
        <div class="d-flex">
          <CIcon
            name="cis-info-circle"
            :class="!noti.read_at ? 'text-info' : 'text-secondary'"
          />
          <TMessage :content="noti.data.title" uppercase noTranslate bold />
        </div>
        <TMessage :content="noti.data.body" noTranslate />
        <TMessageDateTime :content="noti.created_at" small />
      </CDropdownItem>
      <SButtonLoadmore
        store="helper.user_notifications"
        :options="{ size: 'sm' }"
      />
    </div>

    <CDropdownHeader
      class="d-flex cursor-pointer bg-light"
      @click="markAllRead"
    >
      <CIcon name="cil-check" class="text-danger" />
      <TMessage content="Mark all read" bold color="primary" />
    </CDropdownHeader>
  </CDropdown>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TheHeaderDropdownNotif",
  data() {
    return { itemsCount: 5 };
  },
  created() {
    this.$store.dispatch("helper.user_notifications.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      notifs: "helper.user_notifications.list",
      loading: "helper.user_notifications.loading",
    }),
    countUnRead() {
      return this.notifs.filter((x) => !x.read_at).length || 0;
    },
  },
  methods: {
    fetchNoti() {
      this.$store.dispatch("helper.user_notifications.fetch");
    },
    toNoti(noti) {
      if (!noti.read_at) {
        this.$store.commit("helper.user_notifications.select", noti.id);
        this.$store.dispatch("helper.user_notifications.detail.mark-as-read");
      }
      if (noti.data.resource === "order") {
        this.$router
          .push(
            this.lodash.getReferenceLink("order", noti.data.resource_key)
          )

          .catch(() => {});
      }
      // Notification cho kho
      if (noti.data.resource === "sfa") {
        this.$router
          .push(
            this.lodash.getReferenceLink(
              "sfa",
              noti.data.resource_key
            )
          )
          .catch(() => {});
      }
      // Notification cho kho
      if (noti.data.resource === "owning-box") {
        this.$router
          .push(
            this.lodash.getReferenceLink(
              "distributed",
              noti.data.resource_key
            )
          )
          .catch(() => {});
      }
      // Notification cho hợp đồng
      if (noti.data.resource === "contract") {
        this.$router
          .push(
            this.lodash.getReferenceLink(
              "contract",
              noti.data.resource_key
            )
          )
          .catch(() => {});
      }
      // Notification cho ví người dùng
      if (noti.data.resource === "wallet") {
        this.$router
          .push(
            this.lodash.getReferenceLink(
              "customer",
              noti.data.resource_key
            )
          )
          .catch(() => {});
      }
      // Notification cho ví người dùng
      if (noti.data.resource === "support") {
        this.$router
          .push(
            this.lodash.getReferenceLink(
              "support",
              noti.data.resource_key
            )
          )
          .catch(() => {});
      }
    },
    markAllRead() {
      this.$store
        .dispatch("helper.user_notifications.mark-all-as-read")
        .then(() => {
          this.fetchNoti();
        });
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
