<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="
      (value) => $store.commit('sidebar.set', ['sidebar_show', value])
    "
    @mouseover.native="upHere = true"
    @mouseleave.native="upHere = false"
    dropdownMode="closeInactive"
  >
    <CSidebarBrand to="/">
      <CIcon :src="assets_url" height="35" alt="Logo" />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="navStructure" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('sidebar.toggle', 'sidebar_minimize')"
    />
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheSidebar",
  watch: {
    permissions: function () {
      this.$store.dispatch("nav.available.refresh");
    },
    minimize() {
      if (this.minimize) {
        this.assets_url = `${process.env.VUE_APP_ASSETS_URL}/logo-sm.png`;
      }
    },

    upHere() {
      if (this.upHere) {
        this.assets_url = `${process.env.VUE_APP_ASSETS_URL}/logo.png`;
      }
      else{
        if (this.minimize) {
          this.assets_url = `${process.env.VUE_APP_ASSETS_URL}/logo-sm.png`;
        }
      }
    },
  },
  data() {
    return {
      assets_url: `${process.env.VUE_APP_ASSETS_URL}/logo.png`,
      upHere: false,
    };
  },

  computed: {
    ...mapGetters({
      navStructure: "nav.structure",
    }),
    show() {
      return this.$store.state.sidebar.sidebar_show;
    },
    minimize() {
      return this.$store.state.sidebar.sidebar_minimize;
    },
  },
};
</script>

